import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import './App.css';

function App() {
  const [ipRanges, setIpRanges] = useState({ ipv4: [], ipv6: [] });
  const [filteredRanges, setFilteredRanges] = useState({ ipv4: [], ipv6: [] });
  const [visibleRanges, setVisibleRanges] = useState({ ipv4: [], ipv6: [] });
  const [filters, setFilters] = useState({
    service: '',
    ipAddress: '',
    region: '',
  });
  const [ipVersions, setIpVersions] = useState({
    ipv4: true,
    ipv6: false,
  });
  const [availableFilters, setAvailableFilters] = useState({
    services: [],
    regions: [],
  });
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const observer = useRef();
  const lastRangeElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        loadMoreRanges();
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get('https://ip-ranges.amazonaws.com/ip-ranges.json');
        const ipv4Prefixes = response.data.prefixes.map(prefix => ({
          ...prefix,
          ipAddress: prefix.ip_prefix,
          type: 'ipv4',
        }));
        const ipv6Prefixes = response.data.ipv6_prefixes.map(prefix => ({
          ...prefix,
          ipAddress: prefix.ipv6_prefix,
          type: 'ipv6',
        }));
        setIpRanges({ ipv4: ipv4Prefixes, ipv6: ipv6Prefixes });
        setFilteredRanges({ ipv4: ipv4Prefixes, ipv6: ipv6Prefixes });
        setVisibleRanges({ 
          ipv4: ipv4Prefixes.slice(0, 100), 
          ipv6: ipv6Prefixes.slice(0, 100) 
        });
        setTotalCount(ipv4Prefixes.length + ipv6Prefixes.length);

        // Extract unique values for filters
        const allPrefixes = [...ipv4Prefixes, ...ipv6Prefixes];
        const services = [...new Set(allPrefixes.map(p => p.service))];
        const regions = [...new Set(allPrefixes.map(p => p.region))];

        setAvailableFilters({
          services: services.sort(),
          regions: regions.sort(),
        });
      } catch (error) {
        console.error('Error fetching IP ranges:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filterRanges = (ranges) => ranges.filter(range => 
      (filters.service === '' || range.service === filters.service) &&
      (filters.ipAddress === '' || range.ipAddress.includes(filters.ipAddress)) &&
      (filters.region === '' || range.region === filters.region)
    );

    let filteredIpv4 = filterRanges(ipRanges.ipv4);
    let filteredIpv6 = filterRanges(ipRanges.ipv6);

    // Apply sorting
    if (sortConfig.key) {
      const sortedRanges = (ranges) => ranges.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });

      filteredIpv4 = sortedRanges(filteredIpv4);
      filteredIpv6 = sortedRanges(filteredIpv6);
    }

    setFilteredRanges({ ipv4: filteredIpv4, ipv6: filteredIpv6 });
    setVisibleRanges({ 
      ipv4: filteredIpv4.slice(0, 100), 
      ipv6: filteredIpv6.slice(0, 100) 
    });
    setHasMore((filteredIpv4.length > 100) || (filteredIpv6.length > 100));
    setTotalCount(filteredIpv4.length + filteredIpv6.length);
  }, [filters, ipRanges, ipVersions, sortConfig]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleIpVersionChange = (e) => {
    const { name, checked } = e.target;
    setIpVersions(prev => ({
      ...prev,
      [name]: checked,
    }));
  };

  const loadMoreRanges = () => {
    setLoading(true);
    setTimeout(() => {
      const currentIpv4Length = visibleRanges.ipv4.length;
      const currentIpv6Length = visibleRanges.ipv6.length;
      const nextIpv4Ranges = filteredRanges.ipv4.slice(currentIpv4Length, currentIpv4Length + 50);
      const nextIpv6Ranges = filteredRanges.ipv6.slice(currentIpv6Length, currentIpv6Length + 50);
      setVisibleRanges(prev => ({
        ipv4: [...prev.ipv4, ...nextIpv4Ranges],
        ipv6: [...prev.ipv6, ...nextIpv6Ranges],
      }));
      setHasMore((visibleRanges.ipv4.length + nextIpv4Ranges.length < filteredRanges.ipv4.length) ||
                 (visibleRanges.ipv6.length + nextIpv6Ranges.length < filteredRanges.ipv6.length));
      setLoading(false);
    }, 500);
  };

  const resetFilters = () => {
    setFilters({
      service: '',
      ipAddress: '',
      region: '',
    });
    setIpVersions({
      ipv4: true,
      ipv6: true,
    });
    setSortConfig({ key: null, direction: 'ascending' });
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const combinedVisibleRanges = [
    ...(ipVersions.ipv4 ? visibleRanges.ipv4 : []),
    ...(ipVersions.ipv6 ? visibleRanges.ipv6 : [])
  ];

  return (
    <div className="App">
      <header>
        <h1>Amazon IP Address Ranges</h1>
      </header>
      <main>
        <div className="sticky-container">
          <div className="filters-container">
            <div className="filters">
              <div className="filter-group">
                <label htmlFor="service">Service:</label>
                <select
                  id="service"
                  name="service"
                  value={filters.service}
                  onChange={handleFilterChange}
                >
                  <option value="">All Services</option>
                  {availableFilters.services.map(service => (
                    <option key={service} value={service}>{service}</option>
                  ))}
                </select>
              </div>
              <div className="filter-group">
                <label htmlFor="ipAddress">IP Address:</label>
                <input
                  type="text"
                  id="ipAddress"
                  name="ipAddress"
                  value={filters.ipAddress}
                  onChange={handleFilterChange}
                  placeholder="Enter IP or CIDR"
                />
              </div>
              <div className="filter-group">
                <label htmlFor="region">Region:</label>
                <select
                  id="region"
                  name="region"
                  value={filters.region}
                  onChange={handleFilterChange}
                >
                  <option value="">All Regions</option>
                  {availableFilters.regions.map(region => (
                    <option key={region} value={region}>{region}</option>
                  ))}
                </select>
              </div>
              <div className="filter-group ip-version">
                <label>IP Version:</label>
                <div>
                  <input
                    type="checkbox"
                    id="ipv4"
                    name="ipv4"
                    checked={ipVersions.ipv4}
                    onChange={handleIpVersionChange}
                  />
                  <label htmlFor="ipv4">IPv4</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="ipv6"
                    name="ipv6"
                    checked={ipVersions.ipv6}
                    onChange={handleIpVersionChange}
                  />
                  <label htmlFor="ipv6">IPv6</label>
                </div>
              </div>
              <button onClick={resetFilters} className="reset-button">Reset Filters</button>
            </div>
          </div>
          <div className="results-info">
            Showing {combinedVisibleRanges.length} of {totalCount} results
          </div>
          <table>
            <thead>
              <tr>
                <th onClick={() => handleSort('service')}>
                  Service {sortConfig.key === 'service' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                </th>
                <th onClick={() => handleSort('ipAddress')}>
                  IP Address {sortConfig.key === 'ipAddress' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                </th>
                <th onClick={() => handleSort('region')}>
                  Region {sortConfig.key === 'region' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                </th>
                <th onClick={() => handleSort('type')}>
                  Type {sortConfig.key === 'type' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div className="table-container">
          <table>
            <tbody>
              {combinedVisibleRanges.map((range, index) => (
                <tr key={index} ref={index === combinedVisibleRanges.length - 1 ? lastRangeElementRef : null}>
                  <td>{range.service}</td>
                  <td>{range.ipAddress}</td>
                  <td>{range.region}</td>
                  <td>{range.type.toUpperCase()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {loading && <div className="loading">Loading more...</div>}
        {!hasMore && <div className="no-more-results">No more results</div>}
      </main>
      <footer>
        <p>Data sourced from Amazon Web Services</p>
      </footer>
    </div>
  );
}

export default App;